<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="专辑/专题" name="first">
      <div class="box">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
          <table class="detailTable_search" style="width: 100%">
            <tr>
              <td class="titleTd width80px">日期</td>
              <td class="valueTd">
                <div class="block">
                  <el-date-picker v-model="dataForm.time" type="daterange" align="right" unlink-panels
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeHandler"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </td>
              <td class="titleTd width80px">区域</td>
              <td class="valueTd">
                <region-tree-jurisdiction :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择区域"
                  v-model="dataForm.regionId"> </region-tree-jurisdiction>
              </td>
            </tr>
          </table>
        </el-form>

        <div class="wxts_msg_search">
          <span style="margin-left: 15px">截止统计时间:{{ jzrq }}</span>
          <div class="f_t">
            <el-button-group>
              <el-button size="mini" type="success" @click="search()" :disabled="dataListLoading">查询</el-button>
              <el-button size="mini" type="warning" @click="exportData()">{{ $t('export') }}</el-button>
            </el-button-group>
          </div>
        </div>
        <div class="">
          <div id="specialHistogram" class="histogramClass"></div>
          <el-table :data="dataList" style="width: 100%">
            {{ dataList }}
            <el-table-column prop="itemsName" label="专题/专栏" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.itemsName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="playSum" label="播放用户数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.playSum }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="lookSum" label="访问用户数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.lookSum }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="requestSum" label="点播次数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.requestSum }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="playLong" label="播放时长" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.playLong }}</span>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="内容类型" name="second">
      <div>
        <contentIndex ref="contentDataList" v-if="contentVisible"></contentIndex>
      </div>
    </el-tab-pane>
    <el-tab-pane label="产品" name="third">
      <product-index ref="productDataList" v-if="productVisible"></product-index>
    </el-tab-pane>
    <el-tab-pane label="各个媒资内容" name="fourth">
      <media-index ref="mediaDataList" v-if="mediaVisible"></media-index>
    </el-tab-pane>
    <el-tab-pane label="当日新上线媒资内容" name="fifth">
      <MediaOfDayIndex ref="dayMediaDataList" v-if="dayMediaVisible"></MediaOfDayIndex>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { specialCount } from "@/api/kanban/filmWatch";
import contentIndex from './content-index.vue'
import MediaIndex from "./media-index";
import MediaOfDayIndex from "./mediaOfDay-index";
import ProductIndex from "./product-index"
export default {
  data() {
    return {
      activeName: 'first',
      contentVisible: false,
      dataListLoading: false,
      productVisible: false,
      mediaVisible: false,
      dayMediaVisible: false,
      dataList: [],
      jzrq: '',
      dataForm: {
        userState: '01',
        startTime: '',
        endTime: '',
        regionId: '',
        time: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  components: {
    contentIndex, MediaIndex, MediaOfDayIndex, ProductIndex
  },

  activated() {
    this.prdSpecialContent();
    this.getNowDate();
  },

  created() {
  },
  mounted() { },
  computed: {
    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
          return newgridId;
        } else {
          return ''
        }
      }
    },
  },
  watch: {
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name == 'first') {
        this.contentVisible = false;
        this.productVisible = false
        this.mediaVisible = false
        this.dayMediaVisible = false
      }
      if (tab.name == 'second') {
        this.contentVisible = true;
        this.productVisible = false
        this.mediaVisible = false
        this.dayMediaVisible = false
        this.$refs.contentDataList.prdContentType();
      }
      if (tab.name == 'third') {
        this.productVisible = true
        this.contentVisible = false;
        this.mediaVisible = false
        this.dayMediaVisible = false
        this.$refs.productDataList.productContent()
      }
      if (tab.name == 'fourth') {
        this.mediaVisible = true
        this.productVisible = false
        this.contentVisible = false;
        this.dayMediaVisible = false
        this.$refs.mediaDataList.prdMediaContent()
      }
      if (tab.name == 'fifth') {
        this.dayMediaVisible = true
        this.mediaVisible = false
        this.productVisible = false
        this.contentVisible = false;
        this.$refs.dayMediaDataList.prdMediaContent()
      }
      console.log(tab, event);
    },
    changeHandler(val) {
      console.log(val)
      this.dataForm.time = val;
      if (this.dataForm.time != null) {
        this.dataForm.startTime = this.dealdate(this.dataForm.time[0]);
        this.dataForm.endTime = this.dealdate(this.dataForm.time[1]);
      } else {
        this.dataForm.startTime = '';
        this.dataForm.endTime = '';
      }
    },
    /**
     * 日期转换
     */
    dealdate(date) {
      const dt = new Date(date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      this.jzrq = NOW_MONTHS_AGO
      return NOW_MONTHS_AGO
    },
    getObjectValues(object) {
      var values = [];
      for (var property in object)
        values.push(object[property]);
      return values;
    },
    /**
     * 秒转时分秒
     */
    getSecond(time) {
      let second = parseInt(time)
      let minute = 0
      let hour = 0
      if (second > 60) {
        minute = parseInt(second / 60)
        second = parseInt(second % 60)
        if (minute > 60) {
          hour = parseInt(minute / 60)
          minute = parseInt(minute % 60)
        }
      }
      let result = '' + parseInt(second) + '秒'
      if (minute > 0) {
        result = '' + parseInt(minute) + '分' + result
      }
      if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
      }
      return result
    },

    //专题
    prdSpecialContent() {
      const _this = this;
      specialCount(
        {
          startTime: _this.dataForm.startTime,
          endTime: _this.dataForm.endTime,
          areaCode: _this.dataForm.regionId ? _this.dataForm.regionId : (_this.$store.state.user.gridId !== undefined ? _this.$store.state.user.gridId : _this.$store.state.user.regionId)
        }
      ).then(({ data }) => {
        if (data && data.data && data.code === 0) {
          _this.dataList = data.data
          var newdata = data.data;
          _this.specialDataList = newdata;
          const newList = []
          newdata.forEach(element => {
            newList.push(_this.getObjectValues(element))
          });
          _this.specialHistogram(newList)
        }
      })
    },

    specialHistogram(e) {
      const seriesLabelShowtop = {
        show: true,
        position: 'top',
        // fontSize: 12,
        rotate: 30,
        distance: 10,
        textStyle: {
          color: 'black'
        }
      }
      const seriesLabelShowInside = {
        show: true,
        position: 'inside',
        textStyle: {
          color: 'black'
        }
      }
      var myChart = this.$echarts.init(document.getElementById('specialHistogram'))
      var option
      option = {
        dataZoom: {
          show: true,
          height: 5,
          bottom: 10,
          start: 0,
          end: 5,
          showDetail: false
        },
        grid: {
          bottom: '10',
          top: "100",
          containLabel: true
        },
        legend: {
          selected: {
            '播放用户数': false,
            '点播次数': false,
            '播放时长': false
          }
        },
        tooltip: {
          showContent:true,
          // （1）item：数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          // （2）axis：坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          // （3）none：什么都不触发。
          trigger: 'axis',
          // 提示框触发的条件,'mousemove'鼠标移动时触发。'click'鼠标点击时触发。'mousemove|click'同时鼠标移动和点击时触发。'none'不在 'mousemove' 或 'click' 时触发
          triggerOn: 'mousemove',
          axisPointer: {
            // type: 'line', //默认为line，line直线，cross十字准星，shadow阴影
            type: 'line'
          },
          // formatter: (params) => {
          // return params[0].name + '<br>' +
          // params[0].marker + ' ' + params[0].seriesName + ': ' + params[0].data[1] + ' 个' + '<br>' +
          // params[1].marker + ' ' + params[1].seriesName + ': ' + params[1].data[2] + ' 个' + '<br>' +
          // params[2].marker + ' ' + params[2].seriesName + ': ' + params[2].data[3] + ' 次'+ '<br>' +
          // params[3].marker + ' ' + params[3].seriesName + ': ' + params[3].data[4] + ' 小时';
          // },
          textStyle: {
            align: 'left'
          },
        },
        dataset: {
          source: [
            ['product', '访问用户数', '播放用户数', '点播次数', '播放时长'],
            ...e
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 20,
            lineHeight: 18,
            formatter: function (params) {
              var newParamsName = ''// 最终拼接成的字符串
              var paramsNameNumber = params.length// 实际标签的个数
              var provideNumber = 10// 每行能显示的字的个数
              // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
              if (paramsNameNumber > provideNumber) {
                // ********重点在这里********
                newParamsName = params.substring(0, 10) + '..'// 最终拼成的字符串
              } else { // 将旧标签的值赋给新标签
                newParamsName = params
              }
              // 将最终的字符串返回
              return newParamsName
            }
          }
        },
        yAxis: {},
        series: [
          {
            name:'访问用户数',
            type: 'bar',
            barWidth: 20,
            label: seriesLabelShowtop,
            // stack:'1',
            // barGap: '-100%',
            // itemStyle:{
            //   color:function(params){
            //     console.log(params)
            //       if(params.seriesIndex===0){
            //         console.log("访问用户数")
            //         return "yellowgreen"
            //       }else{
            //         return "bisque"
            //       }
            //     },
            // }
          },
          {
            name:'播放用户数',
            type: 'bar',
            barWidth: 20,
            label: seriesLabelShowtop,
            // stack:'1',
            // barGap: '-100%',
          },
          {
            name:'点播次数',
            type: 'bar',
            barWidth: 20,
            label: seriesLabelShowtop,
            // stack:'1',
            // barGap: '-100%',
          },
          {
            name:'播放时长',
            type: 'bar',
            barWidth: 20,
            label: seriesLabelShowtop,
            // stack:'1',
            // barGap: '-100%',
          }
        ],
        // toolbox: {
        //   show: true,
        //   orient: "vertical",
        //   left: "right",
        //   top: "center",
        //   feature: {
        //     saveAsImage: { show: true },  // 保存图表
        //   },
        // },
        toolbox: { //可视化的工具箱
                show: true,
                feature: {
                    // dataView: { //数据视图
                    //     show: true
                    // },
                    restore: { //重置
                        show: true
                    },
                    // dataZoom: { //数据缩放视图
                    //     show: true
                    // },
                    saveAsImage: {//保存图片
                        show: true,
                        name:'专辑/专题',
                    },
                    magicType: {//动态类型切换
                        type: ['bar', 'line']
                    }
                }
            },

      };

      option && myChart.setOption(option);

      // // 方式一：点击有数据的条形柱体查询相关U盘id，获取对应U盘id的列表数据
      myChart.on('click', (params) => {
        // console.log(params)
        var op = myChart.getOption();
        if(params.seriesIndex ===0){
          // console.log(params.dataIndex)
          // console.log(params.seriesIndex)
          // console.log("播放时长")
          console.log(op.series[params.seriesIndex].label.show)
          if(op.series[params.seriesIndex].label.show===true){
            op.series[params.seriesIndex].label.show = false
          }else{
            op.series[params.seriesIndex].label.show = true
          }
          myChart.setOption(op, true);
        }
        if(params.seriesIndex ===1){
          // var op = myChart.getOption();
          if(op.series[params.seriesIndex].label.show===true){
            op.series[params.seriesIndex].label.show = false
          }else{
            op.series[params.seriesIndex].label.show = true
          }
          myChart.setOption(op, true);
        }
        if(params.seriesIndex ===2){
          // var op = myChart.getOption();
          if(op.series[params.seriesIndex].label.show===true){
            op.series[params.seriesIndex].label.show = false
          }else{
            op.series[params.seriesIndex].label.show = true
          }
          myChart.setOption(op, true);
        }
        if(params.seriesIndex ===3){
          // var op = myChart.getOption();
          if(op.series[params.seriesIndex].label.show===true){
            op.series[params.seriesIndex].label.show = false
          }else{
            op.series[params.seriesIndex].label.show = true
          }
          myChart.setOption(op, true);
        }
      })

      // myChart.on('click', { seriesName: '访问用户数' }, (params) => {
      //   // myChart.clear();
      //   console.log(params.seriesIndex)
      //   // myChart.setOption.series[0](seriesLabelNoShow);
      // });

      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },
    // 查询
    search() {
      this.prdSpecialContent()
    },
    exportData() {
      const token = this.$cookie.get('x-tr-mimosa-token') // this.$cookie.get('token')
      // 导出数据
      const time = new Date().getTime()
      const url = this.$http.adornUrl(`/dataflow/olffilmwatch/export?` +
        'token=' + token +
        '&time=' + time +
        '&startTime=' + this.dataForm.startTime +
        '&endTime=' + this.dataForm.endTime +
        '&type=' + 'collection' +
        '&areaCode=' + (this.dataForm.regionId ? this.dataForm.regionId : (this.gridId !== '' ? this.gridId : this.regionId))
      )
      window.location.href = url
    },
  }
};
</script>
<style scoped>
.histogramClass {
  float: left;
  margin-top: 20px;
  width: 100%;
  height: 360px;
  display: flex;
}
</style>
