<template>
  <div class="box">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd">区域</td>
          <td class="valueTd">
            <region-tree :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择区域" v-model="dataForm.regionId"
              style="width: 50%"> </region-tree>
          </td>
          <!-- <td>
          <el-button-group>
          <el-button type="success" @click="search()" :disabled="dataListLoading">查询</el-button>
          <el-button  type="warning" :disabled="dataListLoading">导出</el-button>
        </el-button-group>
        </td> -->
        </tr>
      </table>
    </el-form>

    <div class="wxts_msg_search">
      <div class="f_t">
        <el-button-group>
          <el-button size="mini" type="success" @click="search()" :disabled="dataListLoading">查询</el-button>
          <el-button size="mini" type="warning" @click="exportData()">{{ $t('export') }}</el-button>
        </el-button-group>
      </div>
    </div>
    <div class="">
      <div v-loading="dataListLoading" id="dayMediaHistogram" class="coverClass"></div>
      <el-table :data="dataList" style="width: 100%">
        {{ dataList }}
        <el-table-column prop="filmName" label="影片名称" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.filmName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="playSum" label="播放用户数" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.playSum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lookSum" label="访问用户数" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.lookSum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="requestSum" label="点播次数" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.requestSum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="playLong" label="播放时长" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.playLong }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { prdMediaCount } from "@/api/kanban/filmWatch";
export default {
  data() {
    return {
      activeName: 'first',
      dataListLoading: false,
      dataList: [],
      jzrq: '',
      dataForm: {
        userState: '01',
        startTime: '',
        endTime: '',
        regionId: '',
        time: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  components: {

  },

  activated() {

  },

  created() {
  },
  mounted() {
    this.prdMediaContent();
    this.getNowDate();
  },
  computed: {
    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
          return newgridId;
        } else {
          return ''
        }
      }
    },
  },
  watch: {
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    changeHandler(val) {
      this.dataForm.time = val;
      if (this.dataForm.time != null) {
        this.dataForm.startTime = this.dealdate(this.dataForm.time[0]);
        this.dataForm.endTime = this.dealdate(this.dataForm.time[1]);
      } else {
        this.dataForm.startTime = '';
        this.dataForm.endTime = '';
      }
    },
    search() {
      this.prdMediaContent();
    },
    //媒资内容
    prdMediaContent() {
      const _this = this;
      prdMediaCount(
        {
          startTime: _this.dataForm.startTime,
          endTime: _this.dataForm.endTime,
          type: 'mediaOfDay',
          areaCode: _this.dataForm.regionId ? _this.dataForm.regionId : (_this.$store.state.user.gridId !== undefined ? _this.$store.state.user.gridId : _this.$store.state.user.regionId)
        }
      ).then(({ data }) => {
        if (data && data.data && data.code === 0) {
          var newdata = data.data;
          _this.dataList = newdata;
          const newList = []
          newdata.forEach(element => {
            newList.push(_this.getObjectValues(element))
          });
          _this.mediaHistogram(newList)
          console.log(newList)
        }
      })
    },
    //媒资内容
    mediaHistogram(e) {
      const seriesLabelShowtop = {
        show: true,
        position: 'top',
        // fontSize: 12,
        rotate: 30,
        distance: 10,
        textStyle: {
          color: 'black'
        }
      }
      var myChart = this.$echarts.init(document.getElementById('dayMediaHistogram'));
      var option;
      option = {
        legend: {
          selected: {
            '播放用户数': false,
            '点播次数': false,
            '播放时长': false
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          // formatter: (params) => {
          //     return params[0].name + '<br>' +
          //     params[0].marker + ' ' + params[0].seriesName + ': ' + params[0].data[1] + ' 个' + '<br>' +
          //     params[1].marker + ' ' + params[1].seriesName + ': ' + params[1].data[2] + ' 个' + '<br>' +
          //     params[2].marker + ' ' + params[2].seriesName + ': ' + params[2].data[3] + ' 次'+ '<br>' +
          //     params[3].marker + ' ' + params[3].seriesName + ': ' + params[3].data[4] + ' 小时';
          //     },
          textStyle: {
            align: 'left'
          },
        },
        dataset: {
          source: [
            ['product', '访问用户数', '播放用户数', '点播次数', '播放时长'],
            ...e
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 20,
            lineHeight: 18,
            formatter: function (params) {
              var newParamsName = ''// 最终拼接成的字符串
              var paramsNameNumber = params.length// 实际标签的个数
              var provideNumber = 10// 每行能显示的字的个数
              // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
              if (paramsNameNumber > provideNumber) {
                // ********重点在这里********
                newParamsName = params.substring(0, 10) + '..'// 最终拼成的字符串
              } else { // 将旧标签的值赋给新标签
                newParamsName = params
              }
              // 将最终的字符串返回
              return newParamsName
            }
          }
        },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: 'bar',
            barWidth: 20,
            name:'访问用户数',
            label: seriesLabelShowtop
          },
          {
            type: 'bar',
            barWidth: 20,
            name:'播放用户数',
            label: seriesLabelShowtop
          },
          {
            type: 'bar',
            barWidth: 20,
            name:'点播次数',
            label: seriesLabelShowtop
          },
          {
            type: 'bar',
            barWidth: 20,
            name:'播放时长',
            label: seriesLabelShowtop
          }
        ],
        toolbox: { //可视化的工具箱
                show: true,
                feature: {
                    // dataView: { //数据视图
                    //     show: true
                    // },
                    restore: { //重置
                        show: true
                    },
                    // dataZoom: { //数据缩放视图
                    //     show: true
                    // },
                    saveAsImage: {//保存图片
                        show: true,
                        name:'当日新上线媒资内容',
                    },
                    magicType: {//动态类型切换
                        type: ['bar', 'line']
                    }
                }
            },
      };

      // // 方式一：点击有数据的条形柱体查询相关U盘id，获取对应U盘id的列表数据
myChart.on('click', (params) => {
        // console.log(params)
        var op = myChart.getOption();
        if(params.seriesIndex ===0){
          // console.log(params.dataIndex)
          // console.log(params.seriesIndex)
          // console.log("播放时长")
          console.log(op.series[params.seriesIndex].label.show)
          if(op.series[params.seriesIndex].label.show===true){
            op.series[params.seriesIndex].label.show = false
          }else{
            op.series[params.seriesIndex].label.show = true
          }
          myChart.setOption(op, true);
        }
        if(params.seriesIndex ===1){
          // var op = myChart.getOption();
          if(op.series[params.seriesIndex].label.show===true){
            op.series[params.seriesIndex].label.show = false
          }else{
            op.series[params.seriesIndex].label.show = true
          }
          myChart.setOption(op, true);
        }
        if(params.seriesIndex ===2){
          // var op = myChart.getOption();
          if(op.series[params.seriesIndex].label.show===true){
            op.series[params.seriesIndex].label.show = false
          }else{
            op.series[params.seriesIndex].label.show = true
          }
          myChart.setOption(op, true);
        }
        if(params.seriesIndex ===3){
          // var op = myChart.getOption();
          if(op.series[params.seriesIndex].label.show===true){
            op.series[params.seriesIndex].label.show = false
          }else{
            op.series[params.seriesIndex].label.show = true
          }
          myChart.setOption(op, true);
        }
      })

      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      this.jzrq = NOW_MONTHS_AGO
      return NOW_MONTHS_AGO
    },
    getObjectValues(object) {
      var values = [];
      for (var property in object)
        values.push(object[property]);
      return values;
    },
    /**
       * 日期转换
       */
    dealdate(date) {
      const dt = new Date(date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    exportData() {
      const token = this.$cookie.get('x-tr-mimosa-token') // this.$cookie.get('token')
      // 导出数据
      const time = new Date().getTime()
      const url = this.$http.adornUrl(`/dataflow/olffilmwatch/export?` +
        'token=' + token +
        '&time=' + time +
        '&startTime=' + this.dataForm.startTime +
        '&endTime=' + this.dataForm.endTime +
        '&type=' + 'mediaOfDay' +
        '&areaCode=' + (this.dataForm.regionId ? this.dataForm.regionId : (this.gridId !== '' ? this.gridId : this.regionId))
      )
      window.location.href = url
    },
  }
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100%;
}

.coverClass {
  margin-top: 15px;
  width: 100%;
  height: 350px;

}

.stateClass {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
}

.b-sousuo {
  display: flex;
}

/* .block{
  margin-right: 10px;
} */
</style>
